.navbarLogo {
  width: 340px;
  height: 61px;
  
}


@media (max-width: 1020px){
  .navbarLogo{
  width: 150px;
  height: 40px;
  }
}