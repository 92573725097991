.footer {
  width: 100%;
  height: 17.25rem;
  /* margin-top: 10rem; */
  display: flex;
}

.footerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.footerIcon{
  width: 394px;
  height: 73px;
  margin-top: 30px;
}

.footerImage{
  width: 100%;
  height: 402px;
  position: absolute;
  z-index: -1;
  top: 0;
}

.footerMobile, .footerMail{
  display: flex;
  gap: 8px;
  align-items: center;
  -webkit-margin-bottom: 12px !important;
}

.footerMobile p{
  color: white;
font-size: 16px;
font-family: Inter;
font-weight: 500;
}

.footerMail p{
  color: white;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
}

.footerLocation{
  display: flex;
  align-items: center;
  cursor: pointer;

}

.footerLocation img{
  margin-right: 8px;
}

.footerLocation p{
  color: white;
font-size: 16px;
font-family: Inter;
font-weight: 500;
line-height: 24.16px;
}

.locationDetails{
  color: white;
font-size: 16px;
font-family: Inter;
font-weight: 500;
line-height: 24.16px;
text-align: center;
cursor: pointer;
}

.footerBottom{
  width: 100%;
  padding-top: 18px;
  padding-bottom: 17px;
  /* padding-left: 396px;
  padding-right: 395px; */
  background: #444444;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
font-size: 16px;
font-family: Poppins;
font-weight: 500;
line-height: 26px;
text-align: center;
height: auto;
}

.footerBottom span{
  font-size: 16px;
font-family: Poppins;
font-weight: 500;
line-height: 26px;
text-align: center;
cursor: pointer;
}


@media (min-width: 868px) {
  .footerContent {
    width: 100%;
  }
}

@media (max-width: 900px){
  .footerIcon{
    width: 240px;
    height: 45px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .footerImage{
    width: 100%;
    height: 330px;
    position: absolute;
    z-index: -1;
    top: 0;
  }
  
  .footerMobile, .footerMail{
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 12px;
    
  }
  
  .footerMobile p{
    color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  }
  
  .footerMail p{
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
  }
  
  .footerLocation{
    display: flex;
    gap: 8px;
    align-items: center;
  
  }
  
  .footerLocation p{
    color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24.16px;
  }
  
  .locationDetails{
    color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24.16px;
  text-align: center;
  }
  
  .footerBottom{
    width: 100%;
    padding-top: 9px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background: #444444;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  }

  .footerBottom span{
    font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  margin-top: -4px;
  }
}
