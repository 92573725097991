.mainNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 90%;
}

.navbarList {
  display: none;
  align-items: center;
  column-gap: 2rem;
  -webkit-column-gap: 2rem !important;
}

.navbarList button {
  white-space: nowrap;
}

.humburgerButton {
  display: inline-block;
}

.navBarContact{
  display: none;
  align-items: center;
}

.navBarContact p{
  color: #2E2D2D;
font-size: 16px;
font-family: Inter;
font-weight: 500;
margin-bottom: 0;

}

.navBarContact img{
  width: 15.75px;
  height: 15.75px;
}

.burgerMenuOuter{
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #FAFAFA;
  top: 0;
  z-index: -1;

}

.contactSection{
  position: absolute;
  bottom: 100px;
  left: 20px;
}

.contactSection li{
  font-size: 16px;
  font-weight: 500;
  font-family: poppins;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.menuList{
  margin-top: 100px;
  margin-left: 20px;
}

.menuList li{
  font-size: 20px;
  font-family: poppins;
  font-weight: 500;
  margin-bottom: 10px;
  width: 200px;
  
}

.productList{
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropDownCont{
  width: 40%;
}

.dropDownCont li{
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.dropDownCont img{
  width: 30px;
  height: 30px;
}

.burgerIcon{
  display: none;
}

.closeIcon{
 display: none;
}

@media (max-width: 1020px){

.burgerIcon{
  display: block;
  width: 23px;
  height: 25px;
}

.closeIcon{
  display: block;
  width: 16px;
  height: auto;
}
}

@media (min-width: 1020px) {
  .navbarList {
    display: flex;
  }
  .humburgerButton {
    display: none;
  }

  .navBarContact{
    display: flex !important;
  }
}
