.navbarList {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  -webkit-gap: 2rem;
}

@supports not (gap: 2rem) {
  .navbarList > * + * {
    margin-left: 2rem; /* Apply margin between flex items as a fallback */
  }
}

.navbarList li{
  display: flex;
  align-items: center;
  color: black;
font-size: 16px;
font-family: Roboto;
font-weight: 400;
cursor: pointer;
transition: all 0.3s;
margin-right: 35px;
}


.dropDownContent{
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  z-index: 1;
  top: 50px;
  width: 150px;
}

.hideActiveMenu{
  display: none;
}

.dropDownContent li{
  padding: 10px;
  font-family: poppins;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 150px;
}

.dropDownContent li h4{
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
}

.dropDownContent li img{
  width: 20px;
  height: 20px;
}

.navbarLists{
  padding-bottom: 10px;
  transition: none;
  position: relative;
}



.navBarLine{
  position: absolute;
  width: 100%;
  height: 4px;
  background: #FFCC60;
  top: 30px;
}

.navBarLined{
  position: absolute;
  width: 100%;
  height: 4px;
  background: #FFCC60;
  top: 42px;
}

.navbarLists li h4:hover{
  color: black;
  font-weight: 500;
}
